import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../components/layout'

const TermsOfService = () => {
  const siteTitle = 'Tim Optimum - Terms of Service'
  const siteDescription = 'Pogoji poslovanja Tim Optimum'

  return (
    <Layout>
      <Helmet>
        <title>{siteTitle}</title>
        <meta name="description" content={siteDescription} />
      </Helmet>
      <div id="main">
        <h1>Terms of Service</h1>
        <p>
          PetSOS mobile application and its provider Tim Optimum d.o.o.
          (hereinafter: provider) declares that it will respect the privacy of
          users and their personal data. The field of personal data protection
          in Slovenia is systematically regulated by the Act on the protection
          of personal data (Official Gazette RS, No. 86/04) and from 25 May 2018
          also GDPR (General Data Protection Regulation - Final legal text of
          the Regulation (EU) 2016/679 (EU GDPR).
        </p>
        <p>
          The information in the mobile application is for informational
          purposes only, therefore the owner the app assumes no responsibility
          for any content errors and the accuracy and accuracy of the
          information that may have been published due to mismatch in time,
          input errors, or other unpredictable causes. The user is responsible
          for all activities performed using his user account. In the event of
          any unauthorized use or reasonable suspicion that such access has
          occurred and must notify the owner immediately applications as defined
          in 4.1. Article of these General Terms. Owner the app is not
          responsible for the damage that the user would suffer as a result of
          unauthorized use of the user account or password with which it is
          registered or. logged in into the mobile app. The user undertakes to
          use the mobile application in accordance with these General Terms and
          Conditions, which are stated to have these Terms they complement that
          it will ensure the secrecy and security of passwords and that it will
          comply rules and notices posted on the site ... The application owner
          does not shall be liable for any errors and damages resulting from the
          misrepresentation data and / or other performance from the user's
          sphere, e.g. upon registration or sign in to the mobile app. The app
          owner also reserves the right to change, add or remove content at any
          time without notice, published in the mobile app. All users all
          published content they use it at their own risk, and they must also
          take care of it data protection on your smartphone or tablet as well
          to properly protect your smartphone or tablet on which have downloaded
          and use the PetSOS mobile app at their discretion business reasons
          stop providing a mobile app, which it will be about notify users in a
          timely manner.
        </p>
        <p>
          As a bidder, we undertake not to collect or sell in accordance with
          the law we do not disclose any personal information to third parties.
          Bidder can send information and marketing notices and keeps track of
          email addresses. The e-mail address is used only for communication
          with the user when creating a user account where the user receives on
          their electronic The address is a confirmation mail and must be
          confirmed by the user within 48 hours. The user also uses the email
          address and password when logging into the application, where the
          provider checks for correct access to a user account that already
          exists. The provider may use the user's email address in response to
          the received e-mail. Information that users disclose when submitted
          emails are not distributed and are only used in the above the above
          forms. If a user chooses to delete their user account, it will
          received a confirmation link on his email and when he will confirm his
          intention about deleting a user account at the latest within 48 hours,
          all the information from the databases that refer to it have been
          permanently deleted.
        </p>
        <p>
          All information that is arbitrarily disclosed by the user through the
          form on the mobile to petSOS when posting a lost or found animal, the
          data that they add to the list or database temporarily until the user
          removes the post himself, or more than 90 days have elapsed since the
          release (including any patches then the user automatically deletes the
          post and all the user data that were obtained based on the mobile form
          provided by the user filled in when posting an ad about a lost or
          found animal. Until An electronic mailbox where user data is archived
          has access only mobile app owner and manager. The manager does not
          publish and does not disclose any personal information of readers,
          except in the case of sweepstakes that take place on Facebook. In this
          case, each participant must agree with the public the published rules
          and conditions of the sweepstakes, where unambiguous and clear agrees
          that in the event of participation in the prize draw, his personal
          information (name and the last name she uses on Facebook) publicly
          announced at the conclusion of the prize games, insofar as there will
          be a prize winner. This information will not be used subsequently or
          in any form whatsoever.
        </p>
        <p>
          The user of the petSOS mobile application is under no obligation to
          disclose his identity except if he wants it himself. The user is aware
          that the provider is obliged to make a reasonable request and, on a
          legal basis, to provide user information to state authorities when the
          reasons for suspicion of committing an offense are given prosecuted by
          official duties. The user will be familiar with the mobile terms and
          conditions in advance applications. The user will voluntarily share
          the necessary personal information on the mobile petSOS applications
          that are critical to the purpose of the application - to find the lost
          animal and inform the user to the fullest extent that it is tied to
          the safe raising of domestic animals. The Bidder declares that he will
          handle everything with due diligence and professionalism with
          sensitive information provided by the user volunteered on the petSOS
          app. This will ensure the highest privacy data. The provider further
          undertakes to protect the personal data in accordance with Personal
          Data Protection Laws and GDPR General Data Protection Regulation.
        </p>
        <p>
          The petSOS mobile application uses the Facebook plugin for the purpose
          of signing up, logging in, displaying and sharing content. We have
          included video networks Facebook and YouTube channels. The petSOS
          application may contain links to sites that they are not under our
          control. When you click on a third party link, you will redirected to
          her website. If you visit any of these related websites, read their
          privacy notices. We are not responsible for policies and practices of
          other companies.
        </p>
        <h2>Contact</h2>
        <p>
          If you have any questions or concerns regarding the processing of your
          personal information or if you wish to exercise any of your rights,
          please contact us by email at petsos@timoptimum.com
        </p>
        <p>
          Tim Optimum Ltd.
          <br />
          Sagadin Street 15a, 2250 Ptuj
          <br />
          Slovenia
        </p>
      </div>
    </Layout>
  )
}

export default TermsOfService
